.aboutContainer {
    display: flex;
}

.aboutImage {
    padding: 0 0 0 21px;
    width: 300px;
    height: 330px;
}

@media (max-width: 700px) {
    .aboutContainer {
        flex-direction: column;
    }
    .aboutImage {
        padding: 21px 0 0 0;
        align-self: center;
    }
}
