@font-face {
  font-family: 'OkineSans';
  src: local('OkineSans'), url(./fonts/OkineSans/OkineSans-Regular.otf) format('truetype');
}
html,
body {
  height: 100%;
  margin: 0;
  background-color: #fffef2;
  font-family: 'OkineSans-Regular', sans-serif;
  color: #222222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-size: 15px;
  color: #222222;
  font-weight: 500;
  text-decoration: none;
  padding: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

a:hover {
  color: darkgray;
}

h2 {
  padding-top: 55px;
  margin: 0;
  line-height: 100%;
  font-size: 32px;
  color: #222222;
}

h3 {
  padding-top: 5px;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: #7d7d7d;
}

h4 {
  padding-top: 55px;
  padding-bottom: 5px;
  padding-left: 25px;
  margin: 0;
  color: #222220;
}

p {
  line-height: 1.5;
  font-size: 16px;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.container {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}

.header {
  padding: 55px 0;
  text-align: center;
  font-size: 40px;
  font-weight: 300;
}

.mainBody {
  flex: 1 1 auto;
}

.footer {
  border-top: #7d7d7d 1px dotted;
  margin-top: 34px;
}

.body {
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 950px) {
  .body {
    padding: 0 3%;
  }

  .blogList {
    visibility: hidden;
    display: none;
  }
}
