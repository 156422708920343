.footerContent{
  padding-top: 8px;
  display: flex;
  justify-content: center;
}

.footerImage {
  max-width: 20px;
  max-height: 20px;
}

.youtubeFooterImage {
  max-height: 18px;
}
